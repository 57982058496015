import "../../styles/contact.css";
import React, { useState } from 'react'
import axios from "axios";
import SuccessPage from "./SuccessPage";

import dotenv from 'dotenv';

dotenv.config();

const API_BASE_URL = process.env.REACT_APP_AIRA_API_URL || "https://api-dev.helloaira.io"

const ContactUs = () => {
  const [formData, setFormData] = useState({ name: "", email: "", companyName: "", message: "" });
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    setError('')
    try {
      const response = await axios.post(`${API_BASE_URL}/emailapi/internal-emails/get-in-touch`, formData)
      if (response.data?.isSuccess) {
        setIsSuccess(true)
      }
    } catch {
      setError('Something went wrong! Please try again.')
    } finally {
      setIsLoading(false)
    }
  };

  return (
    <div className='contact-us'>
      <div className='container'>
        {isSuccess ? <SuccessPage /> : (<div className='text-center'>
          <h2 className='fw-lighter'>Get in touch</h2>
          <form className='text-start' onSubmit={handleSubmit}>
            <div className="pb-3">
              <label className="d-block py-1">Your name:</label>
              <input
                type="text"
                name="name"
                className="w-100 p-2 rounded"
                required
                onChange={handleChange}
              />
            </div>
            <div className="pb-3">
              <label className="d-block py-1">Email:</label>
              <input
                type="email"
                name="email"
                className="w-100 p-2 rounded"
                required
                onChange={handleChange}
              />
            </div>
            <div className="pb-3">
              <label className="d-block py-1">Company Name (Optional):</label>
              <input
                type="text"
                name="companyName"
                className="w-100 p-2 rounded"
                onChange={handleChange}
              />
            </div>
            <div className="pb-3">
              <label className="d-block py-1">What can we help you with?</label>
              <textarea required rows={5} className="w-100 p-2 border-0 rounded" name='message'
                onChange={handleChange} />
            </div>
            {error && <h5 className="text-danger error">{error}</h5>}
            <div className='text-center'>
              <button type="submit" className="my-3 aira-background-gradient border-0 py-3 px-5 rounded-4 text-white" disabled={isLoading}>
                Submit
              </button>
            </div>
          </form>
        </div>)}

      </div>
    </div>
  )
}

export default ContactUs