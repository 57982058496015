import React, { useEffect } from "react";
import { animateScroll as scroll } from "react-scroll";
import FeaturesHighlight from "../components/FeaturesHighlight";

const UseCases = () => {
	useEffect(() => {
		scroll.scrollToTop({
			duration: 500,
			smooth: true,
			offset: -70, // Adjust this value if you have a fixed header
		});
	}, []);

	return <div className="my-3">
		<FeaturesHighlight
			title={
				<h1 className="text-center fw-bold ">
					Our Use Cases
				</h1>
			}
		/>
	</div>
};

export default UseCases;
