import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import dotenv from 'dotenv';
import { useAuth } from "../../context/authContext";
import PostDetailSkeleton from "./PostDetailSkeleton";
import "../../styles/PostDetail.css";
import airaAvt from "../../assets/images/aira-circle.png";
import dayjs from "dayjs";
import { animateScroll as scroll } from "react-scroll";
import he from "he"
import arrowRightIcon from "../../assets/icons/arrow-right.svg";

dotenv.config();

const processWordPressContent = (htmlContent) => {
  // Create a DOM parser
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, 'text/html');

  // Find all playlist script elements
  const playlistScripts = doc.querySelectorAll('.wp-playlist-script');

  playlistScripts.forEach(script => {
    try {
      // Parse the JSON data from the script
      const playlistData = JSON.parse(script.textContent);

      // Find the parent playlist container
      const playlistContainer = script.closest('.wp-playlist');

      if (playlistContainer && playlistData.tracks && playlistData.tracks.length > 0) {
        // Get the video element
        const videoElement = playlistContainer.querySelector('video');

        if (videoElement) {
          // Get the first track source
          const firstTrack = playlistData.tracks[0];

          // Set the src attribute directly on the video element
          videoElement.setAttribute('src', firstTrack.src);
          videoElement.setAttribute('width', "100%");
          videoElement.removeAttribute('height');
        }
      }
    } catch (error) {
      console.error('Error parsing playlist data:', error);
    }
  });

  // Return the modified HTML content
  return doc.body.innerHTML;
}

const PostDetail = () => {
  const { slug: postSlug } = useParams(); // Use 'slug' to match the route parameter
  const [post, setPost] = useState(null);
  const { authToken } = useAuth()

  useEffect(() => {
    scroll.scrollToTop({
      duration: 500,
      smooth: true,
      offset: -70, // Adjust this value if you have a fixed header
    });
  }, []);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_WP_SITE_URL}/wp-json/wp/v2/posts?slug=${postSlug}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        setPost(response.data?.[0]);
      } catch (error) {
        console.error("Error fetching post:", error);
      }
    };

    fetchPost();
  }, [postSlug, authToken]);

  if (!post) {
    return <PostDetailSkeleton />;
  }

  const decodedContent = he.decode(post.content.rendered)
  const decodedTitle = he.decode(post.title.rendered)

  return (
    <main className="container my-5 post post-container" id="post-detail-top">
      <section className="text-center post-title">
        <h2>{decodedTitle}</h2>
      </section>
      <section className="mt-4">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center gap-3">
            <img src={airaAvt} alt="Aira Logo" width={40} />
            <span className="text-capitalize">{post.author_name}</span>
          </div>
          <span style={{ color: "#70737f" }}>{dayjs(post.date).format("DD MMM YYYY")}</span>
        </div>
      </section>
      <article>
        <div dangerouslySetInnerHTML={{ __html: processWordPressContent(decodedContent) }} className="my-5" />
      </article>
      <section className="overflow-visible mb-3">
        <div className="card rounded-4 border-0 post-footer-card">
          <div className="card-body d-flex flex-column align-items-center post-footer-card-body">
            <h2>{post.acf?.blog_footer}</h2>
            <button
              className="btn aira-background-gradient fw-semibold d-flex gap-3 justify-content-center align-items-center try-aira-btn mt-3"
              onClick={() =>
              (window.location.href =
                "https://calendly.com/d/ck5g-53m-559/aira-demo")
              }
            >
              Try Aira
              <img src={arrowRightIcon} alt="Arrow Icon" />
            </button>
          </div>
        </div>
      </section>
    </main>
  );
};

export default PostDetail;
