import React from "react";
import arrowRightIcon from "../assets/icons/arrow-right.svg";
import "../styles/HeroBanner.css";
import "../styles/Popup.css";
import VideoSection from "./VideoSection";

const HeroBanner = () => {
	return (
		<div className="container-fluid py-5 hero-banner">
			<div className="d-flex flex-column align-items-center">
				<h1 className="text-black text-center mb-3 py-3 fw-lighter">
					Your AI Copilot for Recruiting
				</h1>
				<p className="fs-5 text-black text-center mb-3 blog-size">
					Automate routine tasks, so you can focus on building relationships.
				</p>
				<VideoSection />
				<button
					className="btn aira-background-gradient mb-5 fw-semibold d-flex gap-3 justify-content-center align-items-center"
					onClick={() =>
					(window.location.href =
						"https://calendly.com/d/ck5g-53m-559/aira-demo")
					}
				>
					Try Aira
					<img src={arrowRightIcon} alt="Arrow Icon" />
				</button>
			</div>
		</div>
	);
};

export default HeroBanner;
