import React from 'react';

const PostSkeleton = ({ isLatestPost }) => {
  return (
    <div className="row">
      {/* Left column - Latest content */}
      <div className={`${isLatestPost ? "col-md-7" : ""}`}>
        {/* Badge */}
        <div className="mb-3">
          <span className="placeholder-glow">
            <span className="placeholder col-4 bg-secondary rounded-pill"></span>
          </span>
        </div>

        {/* Heading */}
        <h2 className="card-title mb-3">
          <span className="placeholder-glow">
            <span className="placeholder col-7"></span>
            <span className="placeholder col-4"></span>
          </span>
        </h2>

        {/* Latest text */}
        <p className="card-text">
          <span className="placeholder-glow">
            <span className="placeholder col-12"></span>
            <span className="placeholder col-12"></span>
            <span className="placeholder col-12"></span>
            <span className="placeholder col-8"></span>
          </span>
        </p>

        {/* Post metadata */}
        <div className="mt-4">
          <span className="placeholder-glow">
            <span className="placeholder col-6"></span>
          </span>
        </div>
      </div>

      {/* Right column - Profile and quote */}
      <div className={`${isLatestPost ? "col-md-5" : ""}`}>
        <div className="card bg-dark h-100">
          <div className="card-body p-3 position-relative">
            {/* Profile image */}
            <div className="text-center my-4">
              <div className="placeholder-glow">
                <span className="placeholder bg-secondary rounded-circle mx-auto"
                  style={{ width: "100px", height: "100px", display: "block" }}></span>
              </div>
            </div>

            {/* Quote */}
            <div className="p-2">
              <span className="placeholder-glow">
                <span className="placeholder col-12 bg-secondary"></span>
                <span className="placeholder col-12 bg-secondary"></span>
                <span className="placeholder col-12 bg-secondary"></span>
                <span className="placeholder col-10 bg-secondary"></span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostSkeleton;