import React, { useEffect } from "react";
import HeroBanner from "../components/HeroBanner";
import HiredEasily from "../components/HiredEasily";
import FeaturesHighlight from "../components/FeaturesHighlight";
import NativelyBuilt from "../components/NativelyBuilt";
import Testimonials from "../components/Testimonials";
import ReadyStarted from "../components/ReadyStarted";
import { scroller } from "react-scroll";
import { Helmet } from "react-helmet";
import "../styles/Home.css";
import Marquee from "../components/Marquee";

const Home = () => {
	useEffect(() => {
		// Scroll to id element on the page load
		const hash = window.location.hash;
		if (hash) {
			const id = hash.replace("#", "");
			scroller.scrollTo(id, {
				duration: 800,
				delay: 0,
				smooth: "easeInOutQuart",
			});
		}
	}, []);

	return (
		<div>
			<Helmet>
				<title>Aira</title>
				<meta name="title" content="Aira" />

				{/* <!-- Open Graph / Facebook --> */}
				<meta
					property="og:image"
					content="/assets/images/meta/aira_social_media_preview.png"
				/>

				{/* <!-- Twitter --> */}
				<meta
					property="twitter:image"
					content="/assets/images/meta/aira_social_media_preview.png"
				/>
			</Helmet>
			<Marquee />
			<HeroBanner />
			<div id="use-cases">
				<FeaturesHighlight maxCount={8} showButton />
			</div>
			<Testimonials />
			<NativelyBuilt />
			<HiredEasily />
			<ReadyStarted />
		</div>
	);
};

export default Home;
