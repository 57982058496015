import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "@fortawesome/fontawesome-free/css/all.min.css";
import GlobalStyles from "./styles/GlobalStyles";
import Header from "./Common/Header";
import Blog from "./pages/Blog";
import PostDetail from "./components/blog/PostDetail";
import Home from "./pages/Home"; // Import the new Home component
import { AuthProvider } from "./context/authContext";
import Contact from "./pages/Contact";
import Privacy from "./pages/Privacy";
import Footer from "./Common/Footer";
import UseCases from "./pages/UseCases";

function App() {
	return (
		<div className="App">
			<AuthProvider>
				<GlobalStyles />
				<Router>
					<Header />
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/blogs" element={<Blog />} />
						<Route path="/use-cases" element={<UseCases />} />
						{/* <Route path="/pricing" element={<Pricing />} /> */}
						<Route path="/post/:slug" element={<PostDetail />} />
						<Route path="/contact" element={<Contact />} />
						<Route path="/privacy/chrome-plugin" element={<Privacy />} />
						{/* Update the route path */}
					</Routes>
					<Footer />
				</Router>
			</AuthProvider>
		</div>
	);
}

export default App;
