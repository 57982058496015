import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import "../styles/VideoSection.css";
import demoVideo from "../assets/videos/241118_AIRADemo_Isaac_V1.mp4";


const VideoSection = () => {
	const playerRef = useRef(null);
	const [isPlaying, setIsPlaying] = useState(false);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					setIsPlaying(true);
				} else {
					setIsPlaying(false);
				}
			},
			{ threshold: 0.5 } // Adjust to control when it starts playing
		);

		if (playerRef.current) {
			observer.observe(playerRef.current);
		}

		return () => {
			if (playerRef.current) {
				observer.unobserve(playerRef.current);
			}
		};
	}, []);

	return (
		<div className="container-fluid py-5 mt-5 video-section">
			<div className="row justify-content-center">
				<div className="col-10">
					<div className="video-wrapper">
						<div className="player-wrapper" ref={playerRef}>
							<ReactPlayer
								className="react-player"
								url={demoVideo}
								playing={isPlaying}
								muted
								controls
								width="100%"
								height="100%"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default VideoSection;
