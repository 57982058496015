import React, { useState, useEffect } from 'react';
import axios from 'axios';
import dotenv from 'dotenv';
import { useAuth } from '../../context/authContext'
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import PostSkeleton from './PostSkeleton';
import he from "he"

dotenv.config();

const CATEGORIES = {
  featured: "Featured",
  interview: "Interview",
  news: "News",
  "aira-insights": "AIRA Insights",
}

const CATEGORIES_TEXT_MAPPING = {
  14: "Featured",
  7: "Interview",
  6: "News",
  8: "AIRA Insights",
}

const CATEGORIES_ENUM = {
  featured: 14,
  interview: 7,
  news: 6,
  "aira-insights": 8,
}

const Category = () => {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const { authToken } = useAuth()
  const [searchParams] = useSearchParams();
  const categoryParam = searchParams.get("category") || 'featured' //Default featured;

  useEffect(() => {
    const fetchPosts = async () => {
      setIsLoading(true)
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_WP_SITE_URL}/wp-json/wp/v2/posts`,
          {
            params: {
              per_page: 100,
              categories: CATEGORIES_ENUM[categoryParam]
            },
            headers: {
              'Authorization': `Bearer ${authToken}`
            }
          }
        );

        setPosts(data);
      } catch (error) {
        console.error("Error fetching posts:", error);
        setError(error);
      } finally {
        setIsLoading(false)
      }
    };

    fetchPosts();
  }, [authToken, categoryParam]);

  return <MainContent posts={posts} isLoading={isLoading} error={error} />;
};

const PostCard = ({ post, isLoading, isShowAuthor, isLatestPost, navigate }) => {
  const decodedTitle = he.decode(post?.title?.rendered)
  const decodedContent = he.decode(post?.excerpt?.rendered)
  return <div className='card rounded-4 pointer' onClick={() => navigate(`/post/${post?.slug}`)}>
    <div className="card-body">
      {isLoading ? <PostSkeleton isLatestPost={isLatestPost} /> : <div className='row'>
        <div className={`${isLatestPost ? 'col-md-7' : ''}`}>
          {post?.categories?.map(item => <span key={item} className={`badge rounded-pill category-${item}`}>{CATEGORIES_TEXT_MAPPING[item]}</span>)}
          <h5 className='post-title'>{decodedTitle}</h5>
          <div dangerouslySetInnerHTML={{ __html: decodedContent }} className='post-content' />
        </div>
        <div className={`${isLatestPost ? 'col-md-5' : ''}`} >
          <div className='post-thumbnail-container'>
            <img src={post?.featured_image_url} alt={decodedTitle || "Post thumbnail"} className='post-thumbnail' />
          </div>
        </div>
        {isShowAuthor && <p className='post-author'>
          Posted by {post?.author_name} on {dayjs(post?.date).format('DD/MM/YY [at] HH:mm')}
        </p>}
      </div>
      }
    </div>
  </div>
}

const MainContent = ({ posts, isLoading, error }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const categoryParam = searchParams.get("category") || 'featured' //Default featured;

  // Initialize with safe defaults to prevent mapping errors
  const latestPost = posts && posts.length > 0 ? posts[0] : null;
  const restPosts = posts && posts.length > 1 ? posts.slice(1) : [];

  const onChangeCategory = (category) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set("category", category); // Update or add the category param

    navigate(`?${newParams.toString()}`, { replace: true }); // Push new query params
  }

  return (
    <div className='blog container my-5'>
      <div className="row">
        <div>
          <h1 className="text-black text-center mb-3 py-3 fw-bold ">
            What's New with Aira?
          </h1>
          <p className="fs-5 text-center mb-3 blog-size">
            Stay updated with our latest posts here
          </p>
        </div>
      </div>
      {!error && posts && posts.length > 0 && (
        <div className='my-5'>
          <PostCard isLoading={isLoading} post={latestPost} navigate={navigate} isLatestPost isShowAuthor />
        </div>
      )}
      <div className='mt-5 mb-2'>
        <div className="row">
          <div className="tab-container">
            <ul className="nav nav-pills" id="categories">
              {Object.entries(CATEGORIES).map(([key, value]) =>
                <li className="nav-item" key={key}>
                  <button className={`${categoryParam === key ? 'active' : ''} nav-link`} onClick={() =>
                    onChangeCategory(key)
                  }>{value}</button>
                </li>)
              }
            </ul>
          </div>
        </div>
      </div>
      <div className='row'>
        {!error && restPosts.length > 0 ? (
          restPosts.map((post) => (
            <div className='col-12 col-md-6 col-lg-4 g-4' key={post.id}>
              <PostCard isLoading={isLoading} post={post} navigate={navigate} />
            </div>
          ))
        ) : (
          !isLoading && posts.length <= 1 && !error && (
            <div className="col-12 text-center my-4">
              <p>No additional posts found in this category.</p>
            </div>
          )
        )}
        {isLoading && Array(3).fill(0).map((_, index) => (
          <div className='col-12 col-md-6 col-lg-4 g-4' key={`skeleton-${index}`}>
            <div className='card rounded-4'>
              <div className="card-body">
                <PostSkeleton />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Category;