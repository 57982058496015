import React from 'react'
import "../../styles/PrivacyPlugin.css";


const PrivacyPlugin = () => {
  return (
    <div className='privacy-plugin'>
      <div className='container my-5 py-5'>
        <h1 className='fw-lighter text-center'>Privacy Policy for Chrome Plugin</h1>
        <section className='mt-5'>
          <h4>Privacy Policy for Aira Chrome Plug-In</h4>
          <p className='effective-date mb-0'>Effective Date: February 17, 2025</p>
        </section>
        <section className='details'>
          <h6>1. Introduction</h6>
          <p>Welcome to the Aira Chrome Plug-In ("Extension"), provided by Blockchainlabs.ai ("Company," "we," "our," or "us"). This Privacy Policy explains how we handle data when you use our Extension.
            This Extension is designed exclusively for subscribed users of our Aira B2B platform and is not available for public use.
            By using this Extension, you acknowledge that you have read, understood, and agreed to this Privacy Policy.</p>
        </section>
        <section className='details'>
          <h6>2. Data Collection & Usage</h6>
          <p>2.1 Personal Information</p>
          <p>We do not collect or store any personal information through the Extension.</p>
          <p>2.2 Non-Personal Data</p>
          <p>We may collect anonymous usage data through third-party analytics tools, including:</p>
          <div className='list'>
            <p>• Google Analytics</p>
            <p>• Microsoft Clarity</p>
          </div>
          <p>This data helps us track errors, improve performance, and enhance user experience. </p>
          <p>2.3 Browser Data</p>
          <p>The Extension does not collect or access your browsing history or any unrelated web data.</p>
          <p>2.4 Data Storage & Third-Party Services</p>
          <div className='list'>
            <p>• Data is stored in the cloud and processed via Microsoft Azure Monitoring for debugging and performance tracking.</p>
            <p>• The Extension does not share data with third parties.</p>
          </div>
        </section>
        <section className='details'>
          <h6>3. Required Permissions & Justifications</h6>
          <p>To function effectively, the Extension requires the following permissions:</p>
          <p>3.1 webRequest</p>
          <div className='list'>
            <p>• Justification: Used to monitor and intercept network requests made to our platform’s API to validate authentication, ensuring only authorized users can access the Extension’s import features.</p>
          </div>
          <p>3.2 webRequestBlocking</p>
          <div className='list'>
            <p>• Justification: Required for modifying or blocking authentication-related requests, allowing secure login management for authenticated users.</p>
          </div>
          <p>3.3 storage</p>
          <div className='list'>
            <p>• Justification: Used to store user preferences, authentication tokens, and temporary session data for seamless data importing.</p>
          </div>
          <p>3.4 activeTab</p>
          <div className='list'>
            <p>• Justification: Allows the Extension to access the currently active web page so that users can extract and import relevant business data.</p>
          </div>
          <p>3.5 tabs</p>
          <div className='list'>
            <p>• Justification: Used to check user authentication status and enable seamless interactions across multiple open tabs of the platform.</p>
          </div>
          <p>3.6 scripting</p>
          <div className='list'>
            <p>• Justification: Required to inject JavaScript into web pages, allowing the structured extraction of relevant business data for import.</p>
          </div>
          <p>3.7 Host Permissions</p>
          <div className='list'>
            <p>• Justification: The Extension requires host permissions to access and extract structured business data from visited web pages. Data is only processed based on user-initiated actions and is never collected or tracked beyond this scope.</p>
          </div>
        </section>
        <section className='details'>
          <h6>4. Data Sharing & Transfers</h6>
          <div className='list'>
            <p>• We do not share user data with third parties.</p>
            <p>• No data transfers occur outside of our controlled cloud environment.</p>
          </div>
        </section>
        <section className='details'>
          <h6>5. Data Security</h6>
          <p>We employ industry-standard security measures, including:</p>
          <div className='list'>
            <p>• SSL encryption for all data transmissions.</p>
            <p>• OAuth authentication to ensure secure user login.</p>
          </div>
          <p>5.1 Data Breach Policy</p>
          <p>In the event of a data breach, we will notify affected users via email as soon as possible.</p>
        </section>
        <section className='details'>
          <h6>6. User Privacy Commitments</h6>
          <div className='list'>
            <p>• We do not track or monitor user activity outside of the Extension’s intended functions.</p>
            <p>• No personal data is collected, stored, or shared.</p>
          </div>
        </section>
        <section className='details'>
          <h6>7. Policy Updates</h6>
          <p>If we make changes to this Privacy Policy, we will notify users via email before the new terms take effect. Effective Date: February 17, 2025</p>
        </section>
        <section className='details'>
          <h6>8. Contact Information</h6>
          <p>If you have any questions or concerns regarding this Privacy Policy, please contact us at:
            📧  <a href="mailto:support@helloaira.io"> support@helloaira.io</a></p>
        </section>
      </div>
    </div>
  )
}

export default PrivacyPlugin