import React, { useEffect, useState } from "react";
import "../styles/FeaturesHighlight.css";
import AOS from "aos";
import "aos/dist/aos.css";
import image1 from "../assets/images/features-highlight/accurate-data.png"
import image2 from "../assets/images/features-highlight/ai-assistant.png"
import image3 from "../assets/images/features-highlight/any-confusion.png"
import image4 from "../assets/images/features-highlight/contextual-memory.png"
import image5 from "../assets/images/features-highlight/research.png"
import arrowRightIcon from "../assets/icons/arrow-right.svg";
import { useNavigate } from "react-router-dom";

const featureData = [
	{
		id: 1,
		title: "Automated Job Creation for Streamlined Hiring",
		description:
			"Aira autonomously handles every step of adding a new role—client verification, contact setup, and job posting—removing the admin burden and accelerating your recruitment process.",
		url: "https://app.helloaira.io/assistant-replay/c8b2e2e5-9078-4ca6-adf8-094d4fe7972f?typingSpeed=10&companyCode=thedemocompany_com",
		icon: "fa-book-open-reader",
		image: image2,
	},
	{
		id: 2,
		title: "LinkedIn Sourcing & Contact Extraction",
		description:
			"Aira automates the sourcing process on LinkedIn, efficiently scanning profiles in Bangkok and extracting essential contact details. This seamless workflow streamlines candidate outreach and minimizes manual efforts.",
		url: "https://app.helloaira.io/assistant-replay/46d64771-b37b-4752-a33c-d557f1b40a0e?typingSpeed=10&companyCode=thedemocompany_com",
		icon: "fa-brain",
		image: image1
	},
	{
		id: 3,
		title: "Web Research & Insights",
		description:
			"Aira autonomously conducts web searches and aggregates real-time market intelligence, delivering concise, actionable insights to keep you informed with minimal effort.",
		url: "https://app.helloaira.io/assistant-replay/666f4827-a80b-405a-a250-7ce735c177ac?typingSpeed=10&companyCode=thedemocompany_com",
		icon: 'fa-earth-asia',
		image: image5
	},
	{
		id: 4,
		title: "Effortless Bulk Candidate Import",
		description:
			"Aira streamlines candidate ingestion by automatically extracting data from documents in any format and seamlessly adding it to your database for quick monitoring and review.",
		url: "https://app.helloaira.io/assistant-replay/7aa6d989-bcb5-447d-a7fc-72b3df4622ac?typingSpeed=10&companyCode=thedemocompany_com",
		icon: 'fa-layer-group',
		image: image4
	},
	{
		id: 5,
		title: "Seamless Spreadsheet Candidate Import",
		description:
			"Aira automatically analyzes spreadsheet structures, maps candidate data, and uploads records to your database—streamlining bulk candidate onboarding with precision and minimal manual input.",
		url: "https://app.helloaira.io/assistant-replay/8a91a4f4-141d-4731-808d-eba2267d7c49?typingSpeed=10&companyCode=thedemocompany_com",
		icon: 'fa-newspaper',
		image: image3
	},
	{
		id: 6,
		title: "Inline Candidate Analysis & Market Insights",
		description:
			"Aira seamlessly understands inline candidate references, generating detailed comparison tables and insightful summaries while integrating external market data for enhanced decision-making.",
		url: "https://app.helloaira.io/assistant-replay/b1e507d3-3bc7-44d6-aa67-8400cf2f8470?typingSpeed=10&companyCode=thedemocompany_com",
		icon: 'fa-chart-line',
		image: image1
	},
	{
		id: 7,
		title: "Intelligent Client-Contact Import",
		description:
			"Aira effortlessly analyzes contact spreadsheets, automatically mapping contacts to their respective companies and enriching profiles with LinkedIn data for a seamless import process.",
		url: "https://app.helloaira.io/assistant-replay/11653a9f-f8ea-4490-9ea5-9ccd330599f5?typingSpeed=10&companyCode=thedemocompany_com",
		icon: 'fa-id-card-clip',
		image: image2
	},
	{
		id: 8,
		title: "Candidate Export Template Creation",
		description:
			"Aira extracts candidate data placeholders from the database, guiding you through creating and uploading a merge template for generating comprehensive candidate export files effortlessly.",
		url: "https://app.helloaira.io/assistant-replay/63043d36-2e9e-4899-b6b0-2ae448bdfd05?typingSpeed=10&companyCode=thedemocompany_com",
		icon: 'fa-square-poll-vertical',
		image: image3
	},
	{
		id: 9,
		title: "End-to-End Candidate Data Transformation",
		description:
			"Our AI recruitment assistant seamlessly imports candidate details from various document formats, transforms the extracted data, and exports selected profiles using your default template—ensuring a smooth, integrated data management workflow.",
		url: "https://app.helloaira.io/assistant-replay/ee342622-bea3-4704-aa23-21c4dd09b172?typingSpeed=10&companyCode=thedemocompany_com",
		icon: 'fa-circle-nodes',
		image: image4
	},
	{
		id: 10,
		title: "Smart Candidate Search & Review",
		description:
			"Aira swiftly searches the candidate database and reviews profiles against specific criteria—delivering top-ranked candidates that match your detailed requirements.",
		url: "https://app.helloaira.io/assistant-replay/3078539b-53ce-46a1-80d8-19b3c11e0176?typingSpeed=10&companyCode=thedemocompany_com",
		icon: 'fa-magnifying-glass-arrow-right',
		image: image5
	},
	{
		id: 11,
		title: "Smart Call Note Integration",
		description:
			"Our AI recruitment assistant automatically retrieves candidate details from your reference, summarizes the call, and adds a detailed note to Sophia Johnson’s profile—ensuring all key discussion points are captured seamlessly.",
		url: "https://app.helloaira.io/assistant-replay/a133d179-46fd-42f1-a1c4-96fc5c9ef9df?typingSpeed=10&companyCode=thedemocompany_com",
		icon: 'fa-notes-medical',
		image: image1
	},
];

const FeaturesHighlight = ({ title, maxCount, showButton }) => {
	const [hoverId, setHoverId] = useState("")
	const navigate = useNavigate()

	useEffect(() => {
		AOS.init({
			duration: 750, // Animation duration
			easing: "ease-out", // Easing function
			once: true, // Whether animation should happen only once
		});
	}, []);

	return (
		<div className="features-highlight py-5">
			<div className="container overflow-hidden">
				{/* Subtext */}
				<div className="d-flex justify-content-center mb-3">
					{title || <h2 className="fw-lighter">Our Use Cases</h2>}
				</div>
				<div className="row" data-aos="fade-up">
					<div>
						<p className="subtext rounded-pill">What can Aira do?</p>
					</div>
				</div>
				<div className="row row-cols-1 row-cols-md-4 g-4 justify-content-center" data-aos="fade-up">
					{(maxCount ? featureData.slice(0, maxCount) : featureData).map((item) => (
						<div className="col" key={item.id}>
							<a
								className="card h-100 d-flex flex-column overflow-hidden rounded-4 feature-card pointer"
								onMouseEnter={() => setHoverId(item.id)}
								onMouseLeave={() => setHoverId('')}
								href={item.url}
								target="_blank"
								rel="noopener noreferrer"
							>
								<div className="card-body flex-grow-1 overflow-hidden">
									<i className={`fa-solid ${hoverId === item.id ? "fa-play-circle" : item.icon} aira-background-gradient rounded-pill`} />
									<h5 className="my-2">{item.title}</h5>
									<span className="card-description">{item.description}</span>
								</div>
								{item.image && <div className="d-flex justify-content-center card-image position-absolute overflow-hidden">
									<img src={item.image} alt={item.title} className="object-fit-cover position-absolute" width={200} />
								</div>}
							</a>
						</div>
					))}
				</div>
				{showButton && <div className="d-flex justify-content-center">
					<button
						className="btn aira-background-gradient mt-4 fw-semibold d-flex gap-3 justify-content-center align-items-center"
						onClick={() => navigate('/use-cases')
						}
					>
						Explore more  use cases
						<img src={arrowRightIcon} alt="Arrow Icon" />
					</button>
				</div>}
			</div>
		</div>
	);
};

export default FeaturesHighlight;
