import React from "react";

const PostDetailSkeleton = () => {
  return (
    <div className="container my-5 post post-container">
      {/* Title Skeleton */}
      <h1 className="text-center fw-bold placeholder-glow post-title">
        <span className="placeholder col-8"></span>
      </h1>

      {/* Author & Meta Info Skeleton */}
      <div className="d-flex align-items-center mt-4 pb-3">
        <div className="placeholder rounded-circle me-2" style={{ width: "40px", height: "40px" }}></div>
        <div className="d-flex flex-column">
          <span className="placeholder col-4"></span>
        </div>
        <div className="ms-auto text-muted placeholder-glow">
          <span className="placeholder col-5"></span>
        </div>
      </div>

      {/* Content Skeleton */}
      <div className="mt-4">
        <h2 className="fw-bold placeholder-glow">
          <span className="placeholder col-6"></span>
        </h2>
        <p className="placeholder-glow">
          <span className="placeholder col-12"></span>
          <span className="placeholder col-10"></span>
          <span className="placeholder col-9"></span>
        </p>
        <p className="placeholder-glow">
          <span className="placeholder col-11"></span>
          <span className="placeholder col-10"></span>
        </p>
        <p className="placeholder-glow">
          <span className="placeholder col-12"></span>
          <span className="placeholder col-9"></span>
        </p>
        <p className="placeholder-glow">
          <span className="placeholder col-12"></span>
          <span className="placeholder col-9"></span>
        </p>
        <p className="placeholder-glow">
          <span className="placeholder col-12"></span>
          <span className="placeholder col-9"></span>
        </p>
        <p className="placeholder-glow">
          <span className="placeholder col-12"></span>
          <span className="placeholder col-9"></span>
        </p>
        <p className="placeholder-glow">
          <span className="placeholder col-12"></span>
          <span className="placeholder col-9"></span>
        </p>
      </div>
    </div>
  );
};

export default PostDetailSkeleton;
